import { Routes, Route } from "react-router-dom";
import Card from "../pages/Card";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<Card />} />
    </Routes>
  );
};

export default AppNavigator;
