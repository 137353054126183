import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import fr from "./locales/fr.json";
import he from "./locales/he.json";

const resources = {
  fr: { translation: fr, meta: { key: "fr", title: "Français" } },
  he: { translation: he, meta: { key: "he", title: "Hebrew" } },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
