import { useTranslation } from "react-i18next";
import "./styles.scss";
import i18next from "i18next";

const InviteCard = () => {
  const { t } = useTranslation();

  return (
    <div name="card" className="invite-card">
      {i18next.resolvedLanguage !== "he" ? (
        <div className="columns">
          <div className="column left">
            <h6>{t("parents.ounissa_sayada")}</h6>
            <h6>{t("parents.moise_levy")}</h6>
            <h6>{t("parents.dov_levy")}</h6>
          </div>
          <div className="column right">
            <h6>{t("parents.helene_uzan")}</h6>
            <h6>{t("parents.richard_barda")}</h6>
            <h6>{t("parents.jacky_barda")}</h6>
          </div>
        </div>
      ) : (
        <></>
      )}
      <p
        className={
          i18next.resolvedLanguage === "he" && "p-text-hebrew no-padding"
        }
      >
        <i>{t("joie_de_faire_part")}</i>
      </p>
      {i18next.resolvedLanguage === "he" && (
        <p className="p-text-hebrew no-padding">
          <i>{t("joie_de_faire_part_suite")}</i>
        </p>
      )}
      {i18next.resolvedLanguage === "fr" ? (
        <div className="names-container">
          <div className="name-item">
            <h1 style={{ textAlign: "right" }}>Tsipora</h1>
            <h4>ציפורה שרון לונה</h4>
          </div>
          <h1>&</h1>
          <div className="name-item">
            <h1>Netanel</h1>
            <h4 style={{ textAlign: "right" }}>נתנאל חיים</h4>
          </div>
        </div>
      ) : (
        <div className="names-container">
          <h1>נתנאל חיים וציפורה שרון לונה</h1>
        </div>
      )}
      {i18next.resolvedLanguage === "fr" ? (
        <p>
          <i>{t("honoré_présence")}</i>
        </p>
      ) : (
        <p className={i18next.resolvedLanguage === "he" && "p-text-hebrew"}>
          <i>שתתקיים בע''ה ביום ראשון</i>
        </p>
      )}
      <h3>
        <i>{t("date")}</i>
      </h3>
      <h3>
        <i>ט' אדר א' תשפ''ד</i>
      </h3>
      <p className={i18next.resolvedLanguage === "he" && "p-text-hebrew"}>
        <i>{t("heure_precise")}</i>
      </p>
      <p className={i18next.resolvedLanguage === "he" && "p-text-hebrew"}>
        <i>{t("address")}</i>
      </p>
      <h5>{t("jour_de_simha")}</h5>
      <h5>{t("merites")}</h5>
      <p className={i18next.resolvedLanguage === "he" && "p-text-hebrew"}>
        <i>{t("reponse_souhaitée")}</i>
      </p>
      {i18next.resolvedLanguage === "he" ? (
        <div className="columns">
          <div className="column left">
            <h6
              className={i18next.resolvedLanguage === "he" && "h6-text-hebrew"}
            >
              דב ונטלי לוי
            </h6>
          </div>
          <div className="column right">
            <h6
              className={i18next.resolvedLanguage === "he" && "h6-text-hebrew"}
            >
              יעקב וקארין יעל ברדה
            </h6>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InviteCard;
