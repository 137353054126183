import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

const Button = ({ loading, disabled, onClick, children }) => {
  return (
    <button
      className={`ui-button ${disabled ? "ui-button-disabled" : ""}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="ui-button-loading">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : null}

      {children}
    </button>
  );
};

export default Button;
