import "./styles.scss";
import i18next, { changeLanguage } from "i18next";
import fr from "../../assets/fr.png";
import il from "../../assets/il.png";
import { useState } from "react";
import clsx from "clsx";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18next.resolvedLanguage
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language);
  };

  return (
    <div className="language-selector">
      <div className="language-toggle-switch">
        <div
          className={clsx(
            "language-item",
            selectedLanguage === "fr" && "language-item-active"
          )}
          onClick={() => handleLanguageChange("fr")}
        >
          <img src={fr} alt="Français" width={28} />
        </div>
        <div
          className={clsx(
            "language-item",
            selectedLanguage === "he" && "language-item-active"
          )}
          onClick={() => handleLanguageChange("he")}
        >
          <img src={il} alt="עברי" width={28} />
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
