import { useState, useRef, useEffect } from "react";
import Input from "../Input";
import api from "../../api/api";
import { toast } from "react-toastify";
import "./styles.scss";
import Button from "../Button";
import SwitchSelector from "react-switch-selector";
import { useTranslation } from "react-i18next";

// types: mairie, reception, henne, shabbat
const FormCard = ({ types, type }) => {
  const { t } = useTranslation();

  const formRef = useRef();

  const [name, setName] = useState("");
  const [word, setWord] = useState("");
  const [hasPosted, setPosted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [counts, setCounts] = useState(
    Object.fromEntries(Object.keys(types).map((x) => [x, undefined]))
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSent = localStorage.getItem("is-sent");
    if (isSent) {
      setPosted(true);
    }
  }, []);

  const options = [
    { label: t("assisteront"), value: 0 },
    { label: t("not-assisteront"), value: 1 },
  ];

  const onChange = (newValue) => {
    setCurrentIndex(newValue);
  };

  const setCount = (countName, value) => {
    setCounts((previousState) => {
      const newState = { ...previousState };
      newState[countName] = value;
      return newState;
    });
  };

  const sendRSVP = () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }
    if (!name) {
      toast(t("error_name"));
      return;
    }

    let request = { name: name, type: type, word: word };
    if (currentIndex === 0) {
      for (const item in counts) {
        const currentCount = counts[item];
        if (currentCount && currentCount > 0) {
          if (currentCount > 9) {
            toast(t("error_nombre"));
            return;
          }

          request[`${item}Count`] = currentCount;
        }
      }
    }

    setLoading(true);
    api
      .postRSVP(request)
      .then((response) => {
        console.log(response);
        setPosted(true);
        localStorage.setItem("is-sent", true);
      })
      .catch((err) => {
        toast(t("error_envoi"));
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendButtonDisabled = () => {
    return (
      name.length === 0 ||
      (currentIndex === 0 &&
        (counts.reception === undefined ||
          counts.reception === "" ||
          parseInt(counts.reception) <= 0))
    );
  };

  return (
    <div className="form-card">
      <h2 className="form-card-title">{t("rsvp")}</h2>
      {hasPosted ? (
        <p>{t("merci_reponse")}</p>
      ) : (
        <div>
          <form ref={formRef}>
            <Input
              label={t("noms_prenoms")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              required
            />

            <SwitchSelector
              onChange={onChange}
              options={options}
              initialSelectedIndex={currentIndex}
              selectedBackgroundColor={"#4482B9"}
              selectedFontColor={"#fff"}
              fontColor={"#02141b"}
              wrapperBorderRadius={8}
              optionBorderRadius={8}
              border={"none"}
            />
            {currentIndex === 0
              ? Object.keys(types).map((type) => {
                  return (
                    <Input
                      label={types[type]}
                      type="number"
                      value={counts[type]}
                      onChange={(e) => setCount(type, e.target.value)}
                      min={0}
                      max={9}
                      id={type}
                    />
                  );
                })
              : null}
            <Input
              label={t("petit_mot")}
              value={word}
              onChange={(e) => setWord(e.target.value)}
              id="word"
            />
          </form>
          <div className="button-container">
            <Button
              onClick={sendRSVP}
              disabled={sendButtonDisabled()}
              loading={loading}
            >
              <p>{t("envoyer")}</p>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormCard;
