import GoogleMap from "../../components/Map";
import "./styles.scss";
import FormCard from "../../components/FormCard";
import Footer from "../../components/Footer";
import InviteCard from "../../components/InviteCard";
import SplashScreen from "../../components/SplashScreen";
import LanguageSelector from "../../components/LanguageSelector";
import { useTranslation } from "react-i18next";
import GoWaze from "../../components/GoWaze";
import { useEffect } from "react";

function Card() {
  const { t } = useTranslation();

  useEffect(() => {
    const setHeight = () => {
      const cardWrapperElement = document.getElementById(
        "card-wrapper-element"
      );
      if (cardWrapperElement) {
        cardWrapperElement.style.height = window.innerHeight + "px";
      }
    };

    window.addEventListener("resize", setHeight);
    setHeight();

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  return (
    <div className="card-wrapper" id="card-wrapper-element">
      <section className="card-section-first">
        <SplashScreen />
      </section>
      <section id="second-section" className="card-section card-container">
        <LanguageSelector />
        <InviteCard />
      </section>
      <section className="card-section card-container">
        <GoogleMap query="Harmonia BaGan, admo 28, Gedera" />
        <GoWaze />
        <FormCard types={{ reception: t("nombre_personne") }} type="Houppa" />
        <Footer />
      </section>
    </div>
  );
}

export default Card;
