import axios from "axios";

const base_url = "https://rsvp.toubiana.fr/api";
const urls = {
  rsvp_url: `${base_url}/rsvp`,
};

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const request_get = (url, parameters = null) => {
  let config = { headers };
  if (parameters) {
    config.params = parameters;
  }
  return axios.get(url, config);
};

const request_post = (url, parameters = null) => {
  let config = { headers };
  return axios.post(url, parameters, config);
};

const helpers = { urls, request_get, request_post };

export default helpers;
