import i18next from "i18next";
import "./styles.scss";

const Input = ({
  label,
  placeholder,
  type,
  value,
  required,
  onChange,
  min,
  max,
  id,
}) => {
  return (
    <div className="input">
      {label ? (
        <label
          className="input-label"
          htmlFor={id}
          style={
            i18next.resolvedLanguage === "fr"
              ? { textAlign: "left" }
              : { textAlign: "right" }
          }
        >
          {required && i18next.resolvedLanguage === "he" && <span>*</span>}
          {label}
          {required && i18next.resolvedLanguage === "fr" && <span>*</span>}
        </label>
      ) : null}
      <input
        className="input-input"
        style={
          i18next.resolvedLanguage === "fr"
            ? { textAlign: "left" }
            : { textAlign: "right" }
        }
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        id={id}
      />
    </div>
  );
};

export default Input;
