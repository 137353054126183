import helpers from "./helpers";

const postRSVP = (parameters) => {
  return helpers.request_post(helpers.urls.rsvp_url, parameters);
};

const api = {
  postRSVP,
};

export default api;
