import wedding_card from "../../assets/startup_logo.png";
import "./styles.scss";

const SplashScren = () => {
  return (
    <div className="splash-screen">
      <div className="splash-screen-container">
        <img
          className="logo-wedding-card"
          src={wedding_card}
          alt="Tsipora & Netanel logo"
        />
      </div>
    </div>
  );
};

export default SplashScren;
